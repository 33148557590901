html, body {
    @apply h-full w-full bg-[#e1e1e1] m-0 p-0;
}

h1, h2, h3, h4, h5, h6, p, button {
font-family: 'Afacad', sans-serif;
font-optical-sizing: auto;
}

.cedarville-cursive-regular {
    font-family: "Cedarville Cursive", cursive;
    font-weight: 400;
    font-style: normal;
  }
  
.language-python .top-blue { color: #d8f8ff; }
.language-python .middle-blue { color: #6FD3F9; }
.language-python .bottom-blue { color: #d8f8ff; }

.language-python .top-purple { color: #d9d9ff; }
.language-python .middle-purple { color: #b3b3ff; }
.language-python .bottom-purple { color: #d9d9ff; }

.language-python .top-pink { color: #FFD8FF; }
.language-python .middle-pink { color: #FFB3FF; }
.language-python .bottom-pink { color: #FFD8FF; }

.language-javascript .top-blue { color: #d8f8ff; }
.language-javascript .middle-blue { color: #6FD3F9; }
.language-javascript .bottom-blue { color: #d8f8ff; }

.language-javascript .top-purple { color: #d9d9ff; }
.language-javascript .middle-purple { color: #b3b3ff; }
.language-javascript .bottom-purple { color: #d9d9ff; }

.language-javascript .top-pink { color: #FFD8FF; }
.language-javascript .middle-pink { color: #FFB3FF; }
.language-javascript .bottom-pink { color: #FFD8FF; }

.language-rust .top-blue { color: #d8f8ff; }
.language-rust .middle-blue { color: #6FD3F9; }
.language-rust .bottom-blue { color: #d8f8ff; }

.language-rust .top-purple { color: #d9d9ff; }
.language-rust .middle-purple { color: #b3b3ff; }
.language-rust .bottom-purple { color: #d9d9ff; }

.language-rust .top-pink { color: #FFD8FF; }
.language-rust .middle-pink { color: #FFB3FF; }
.language-rust .bottom-pink { color: #FFD8FF; }

